export function safeJSONParse(jsonString) {
    try {
        let result = jsonString;

        // Keep trying to parse until we get a non-string result
        while (typeof result === 'string') {
            // Remove outer quotes if they exist
            result = result.replace(/^["']|["']$/g, '');

            try {
                // Try parsing without unescaping first
                result = JSON.parse(result);
            } catch {
                // If that fails, try unescaping and parsing
                const unescaped = result.replace(/\\/g, '');
                result = JSON.parse(unescaped);
            }
        }

        return result;
    } catch (error) {
        console.error('Failed to parse JSON:', error);
        return null;
    }
}