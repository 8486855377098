import axios from '@/axios'

export async function uploadFile(file, module) {
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('module', module)

    const response = await axios.post('/v3/misc/upload', formdata)
    if (response.status === 200) {
        return response.data.data
    }
    return null
}