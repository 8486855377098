<template>
	<section>
		<!-- Header -->
		<section class="d-block" name="DokumenAmandemen" v-can="'kontrak/detail/amandemen/read'">
			<div class="row align-items-center justify-content-between">
				<div class="col-12 col-sm-8 col-lg-6 mb-1">
					<h4 class="card-title mb-0">
						Dokumen Amandemen
						<button type="button" v-can="'kontrak/detail/amandemen/create'"
							class="btn btn-primary btn-block-mobile" @click="openFormAddAmandemen('option')">
							Tambah Amandemen
						</button>
					</h4>
				</div>
			</div>
		</section>

		<!-- Amandement Proses Section -->
		<section v-if="amendmentProcess.length" class="d-block" name="DokumenAmandemen"
			v-can="'kontrak/detail/amandemen/read'">
			<div class="row align-items-center justify-content-between mb-2">
				<div class="col-12 col-sm-8 col-lg-6">
					<h4 class="card-title mb-0">
						Amandemen Proses
					</h4>
				</div>

				<div v-if="amendmentProcess.length" class="col-12 col-sm-4">
					<div class="input-group form-search-rounded">
						<input class="form-control py-2 border-right-0 border" type="search" placeholder="Cari" />
						<span class="input-group-append">
							<div class="input-group-text bg-transparent">
								<a href="#" class="text-blue"><i class="fa fa-search"></i></a>
							</div>
						</span>
					</div>
				</div>
			</div>

			<div v-for="(amendment, amendmentProcessIdx) in amendmentProcess" :key="amendment.id">
				<div class="text-center"
					style="background: #03566B; color: white; position: relative; padding: 1rem 0;">{{
						amendment.name }} |
					Perihal: {{ getPerihalText(amendment.perihal) }}
					<button type="button" class="btn btn-icon-small text-white"
						style="position: absolute; right: 10px; top: 5px;" @click="deleteAmendment(amendment.id)">
						<i class="fa fa-trash"></i>
					</button>
				</div>
				<div class="table-responsive no-sort w-100 mb-2">
					<table class="table tabel-status-document w-100" style="white-space: nowrap;">
						<thead>
							<tr>
								<th></th>
								<th>Tanggal</th>
								<th>Uraian Proses</th>
								<th>Nomor</th>
								<th v-can="'kontrak/detail/amandemen/detail'">
									<i class="feather icon-download-cloud fa-l"></i> File
								</th>
								<th>Durasi Berjalan</th>
								<th>Status</th>
								<th>Keterangan</th>
								<th class="w10">Aksi</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(doc, docProcessIdx) in amendment.documents" :key="doc.id">
								<td>
									<input type="checkbox" :checked="doc.status == '5'" onclick="return false;" />
								</td>
								<td>{{ doc.tanggal }}</td>
								<td>{{ doc.name }}</td>
								<td>{{ doc.nomor }}</td>
								<td class="file-detail" v-can="'kontrak/detail/amandemen/detail'">
									<a v-if="doc.document" @click="pdfViewer(doc.document)" href="javascript:;">[Lihat
										Detail]</a>
									<span v-else>-</span>
								</td>
								<td>{{ doc.duration ? `${Math.abs(doc.duration)} Hari` : '-' }}</td>
								<td>
									<div v-if="doc.status" class="alert mb-0 text-center"
										:class="getAmendmentStatusColor(doc.status)" role="alert">
										{{ getAmendmentStatusText(doc.status) }}
									</div>
									<span v-else>-</span>
								</td>
								<td><input type="email" class="form-control" id="exampleFormControlInput1"
										v-model="doc.notes" @focus="onNotesFocus(doc)" @blur="updateNotes(doc)"
										@keydown.enter="updateNotes(doc)"></td>
								<td>
									<div class="btn-action-block">
										<button type="button"
											class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
											v-can="'kontrak/detail/amandemen/update'" @click="openDetail({
												...doc,
												// for amandement parent index
												amendmentProcessIdx,
												// for spesific process index
												docProcessIdx
											}, 'edit')">
											<i class="fa fa-pencil"></i>
										</button>
										&emsp;
										<button type="button" v-can="'kontrak/detail/amandemen/detail'"
											@click="openDetail(doc, 'detail')"
											class="btn btn-icon-small btn-success-trans waves-effect waves-light">
											<i class="fa fa-eye"></i>
										</button>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="9" class="text-center">Total durasi: {{
									getTotalDuration(amendment.documents) }}</td>
							</tr>
							<tr v-if="!amendment.documents.length">
								<th colspan="9" class="text-center">Tidak ada data.</th>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<hr class="my-2" />
		</section>

		<!-- Form Pilih Jenis Amandemen (pusat / unit) -->
		<div class="modal fade text-left show" id="Modal_AmandemenOption" aye="mdaman" tabindex="-1" role="dialog"
			aria-labelledby="myModalLabel160" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<!-- Header -->
					<div class="modal-header modal-primary white">
						<h5 class="modal-title">
							Pilih Jenis Amandemen
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>


					<!-- Body -->
					<div class="modal-body">
						<button @click="openFormAddAmandemen('Kontrak Unit')" class="amandemen-option-btn">Tambah
							Amandemen
							Kontrak
							Unit</button>
						<hr>
						<button @click="openFormAddAmandemen('Kontrak Pusat')" class="amandemen-option-btn">Tambah
							Amandemen
							Kontrak
							Pusat</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Form Update Amandemen -->
		<div class="modal fade text-left show" id="Modal_AmandemenUpdate" aye="mdaman" tabindex="-1" role="dialog"
			aria-labelledby="myModalLabel160" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div v-if="amendmentDoc" class="modal-content">
					<ValidationObserver ref="amandemenUpdateForm">
						<!-- Header -->
						<div class="modal-header modal-primary white">
							<h5 class="modal-title">
								{{ isDetailModal ? 'Detail' : 'Upload file' }}
							</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>


						<!-- Body -->
						<div class="modal-body">
							<div v-show="!isDetailModal" class="form-group">
								<vue-dropzone ref="amandemenDropzone" id="wjw" :duplicateCheck="true"
									@vdropzone-files-added="amandemenFileHandler"
									:options="kontraktual_dropzoneOptions">
								</vue-dropzone>
								<button v-if="Boolean(amendmentDoc.document)" @click="amandemenDeleteFile"
									class="btn btn-danger" style="margin-top: 10px">
									Reset File
								</button>
								<ValidationProvider :rules="amendmentDoc.status == '5' ? 'required' : ''" name="Dokumen" v-slot="{ errors }">
									<input type="hidden" v-model="amendmentDoc.document" />
									<p style="color: #ea5455">{{ errors[0] }}</p>
								</ValidationProvider>
							</div>
							<div v-show="isDetailModal" class="form-group">
								<label>{{ amendmentDoc.name }}</label>
								<div v-if="amendmentDoc" class="input-group">
									<input disabled type="text" class="form-control" :value="amendmentDoc?.name">
									<span class="input-group-btn">
										<button @click="pdfViewer(amendmentDoc.document)" class="btn btn-default"
											type="button">Lihat Detail</button>
									</span>
								</div>
							</div>

							<div class="form-group">
								<ValidationProvider name="Nomor Amandemen" v-slot="{ errors }" :rules="amendmentDoc.status == '5' ? 'required' : ''">
									<label for="filter_by">Nomor</label>
									<input :disabled="isDetailModal" v-model="amendmentDoc.nomor" type="text"
										class="form-control" placeholder="Nomor Amandemen" :class="Boolean(errors[0])
											? 'form-control is-invalid'
											: 'form-control'
											" />
									<small>{{ errors[0] }} </small>
								</ValidationProvider>
							</div>

							<div class="form-group">
								<ValidationProvider name="Tanggal" v-slot="{ errors }" :rules="docDateRules">
									<label for="">Tanggal Dokumen</label>
									<div class="position-relative has-icon-right">
										<date-picker :disabled="isDetailModal" v-model="amendmentDoc.tanggal"
											:disabled-date="date => documentDateValidation(date, amendmentProcess[amendmentDoc.amendmentProcessIdx].documents[amendmentDoc.docProcessIdx - 1]?.tanggal)"
											format="DD/MM/YYYY" type="date" :input-class="Boolean(errors[0])
												? 'form-control is-invalid no-x'
												: 'form-control'
												" value-type="YYYY-MM-DD" placeholder="Masukan tanggal dokumen (dd/mm/yyyy)" style="width: 100%">
										</date-picker>
									</div>
									<small>{{ errors[0] }} </small>
								</ValidationProvider>
							</div>

							<div class="form-group">
								<ValidationProvider name="Status" v-slot="{ errors }">
									<label for="">Status</label>
									<select :disabled="isDetailModal || amendmentDoc.status == '5'" class="form-control" :class="Boolean(errors[0])
										? 'form-control is-invalid'
										: 'form-control'
										" v-model="amendmentDoc.status">
										<option :value="null">- Pilih Status -</option>
										<option v-for="status in amendmentStatus" :key="status.id"
											:value="status.level">{{
												status.name
											}}</option>
									</select>
									<small>{{ errors[0] }} </small>
								</ValidationProvider>
							</div>
						</div>

						<div v-if="!isDetailModal" class="modal-footer">
							<button type="button" class="btn btn-outline-primary" data-dismiss="modal">
								Tutup
							</button>
							<button @click="updateDoc" type="button" class="btn btn-primary">
								<span>Simpan</span>
							</button>
						</div>
					</ValidationObserver>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { safeJSONParse } from '@/utils/json.js'
import { uploadFile } from '@/utils/file.js'
import ld from "lodash";
import moment from 'moment'

export default {
	name: 'AmandemenProsesSection',
	props: {
		amendmentProcess: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			actionType: null,
			amendmentType: null,
			amendmentAction: null,
			amendmentDoc: null,
			amendmentStatus: [],
			activeNote: {},
			kontraktual_dropzoneOptions: {
				url: this.$baseUrl + "/v3/misc/upload",
				thumbnailWidth: 150,
				acceptedFiles: ".doc,.docx,application/msword,.pdf",
				autoProcessQueue: false,
				addRemoveLinks: false,
				uploadMultiple: true,
				maxFiles: 1,
				headers: { Authorization: "Bearer " + this.$store.state.token },
				paramName: "file",
				maxFilesize: 10000,
			},
		}
	},
	watch: {
		amendmentWatcher() {
			this.$nextTick(() => {
				this.$refs.amandemenDropzone?.removeAllFiles()
				if (this.amendmentDoc.document) {
					let file = { size: 10240, name: this.amendmentDoc.name, type: "application/word" };
					let url = this.amendmentDoc.document;
					this.$refs.amandemenDropzone?.manuallyAddFile(file, url);
				}
			})
		}
	},
	computed: {
		isDetailModal() {
			return this.amendmentDoc && this.amendmentAction === 'detail'
		},
		amendmentWatcher() {
			return this.amendmentDoc || this.amendmentAction
		},
		docDateRules() {
			if (this.isDetailModal) return null;

			const previousDate = this.getPreviousDocumentDate();
			const dateOrderRule = previousDate ? `date_order:${previousDate}` : '';

			return this.amendmentDoc.status === '5'
				? `required|${dateOrderRule}`.trim()
				: dateOrderRule || null;
		},
	},
	methods: {
		getPreviousDocumentDate() {
			return this.amendmentProcess[this.amendmentDoc.amendmentProcessIdx]
				?.documents[this.amendmentDoc.docProcessIdx - 1]?.tanggal;
		},
		openDetail(doc, action) {
			this.amendmentDoc = ld.cloneDeep(doc)
			this.amendmentAction = action
			this.$nextTick(() => {
				window.$("#Modal_AmandemenUpdate").modal("show");
			})
		},
		openFormAddAmandemen(action) {
			// Open form action options
			if (action === 'option') {
				window.$("#Modal_AmandemenOption").modal("show");
			} else {
				// Open form input
				this.amendmentType = action
				this.$emit('onOpenForm', {
					action: 'add',
					amendmentType: action
				})
			}
		},
		getAmendmentStatus() {
			let _ = this;
			return _.axios
				.get(`/v3/misc/amandemen-status`)
				.then((resp) => {
					_.amendmentStatus = resp.data.data;
				})
				.catch((err) => {
					console.log(err.response);
				});
		},
		getTotalDuration(documents) {
			let total = 0
			documents.forEach(doc => {
				total += Math.abs(doc.duration)
			})
			
			return `${total} Hari`
		},
		getAmendmentStatusColor(status) {
			const statusColors = {
				0: 'alert-warning', // under review
				3: 'alert-danger', // dokumen tidak lengkap
				5: 'alert-success', // success
			};

			return statusColors[status] || 'alert-info';
		},
		getAmendmentStatusText(status) {
			return this.amendmentStatus.find(s => s.level == status)?.name ?? null;
		},
		getPerihalText(perihal) {
			const _perihal = safeJSONParse(perihal)
			return _perihal.map(p => p.name).join(', ')
		},
		amandemenFileHandler(file) {
			let _ = this;

			if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
				window.$.each(file, function (index, file) {
					_.amendmentDoc.document = file
				});
			} else {
				_.$refs.amandemenDropzone.removeAllFiles()
			}
		},
		amandemenDeleteFile() {
			let _ = this;
			_.$refs.amandemenDropzone.removeAllFiles()
			_.amendmentDoc.document = null
		},
		onNotesFocus(doc) {
			this.activeNote[doc.id] = doc.notes;
		},
		updateNotes(doc) {
			let _ = this;
			if (this.activeNote[doc.id] === doc.notes) {
				return;
			}
			return _.axios
				.put(`/v3/kontrak/${doc.id}/amandemen-proses/notes`, { notes: doc.notes })
				.then((resp) => {
					console.log({ resp });
					_.$toast.success(`Catatan ${doc.name} berhasil disimpan`);
				})
				.catch((err) => {
					console.log(err.response);
				});
		},
		async updateDoc() {
			let _ = this;
			var validation = _.$refs.amandemenUpdateForm;
			validation.validate().then(async (success) => {
				if (!success) {
					return;
				}
				// Extract document from amendmentDoc
				const documentSource = _.amendmentDoc.document;

				// If documentSource is null or undefined, set document to null
				// Otherwise process based on type
				const document = !documentSource
					? null
					: typeof documentSource === 'string'
						? documentSource
						: await uploadFile(documentSource, 'amandemen_proses');

				_.axios
					.put(`/v3/kontrak/${_.amendmentDoc.id}/amandemen-proses`, {
						nomor: _.amendmentDoc.nomor,
						document,
						tanggal: _.amendmentDoc.tanggal,
						status: _.amendmentDoc.status
					})
					.then(() => {
						_.$toast.success(`Dokumen berhasil diupdate`);
						window.$("#Modal_AmandemenUpdate").modal("hide");
						this.$emit('onRefreshList');
					})
					.catch((err) => {
						console.log(err.response);
					})
			})
		},
		documentDateValidation(date, prevDate) {
			const today = moment().startOf('day'); // Ensure today is compared without time
			const selectedDate = moment(date).startOf('day'); // Remove time from the selected date

			// If no previous date exists, date should not be in the future
			if (!prevDate) {
				return selectedDate.isAfter(today); // Invalid if after today
			}

			const previousDate = moment(prevDate).startOf('day'); // Remove time from prevDate

			// If previous date exists, date should be between prevDate and today (inclusive)
			return selectedDate.isBefore(previousDate) || selectedDate.isAfter(today);
		},
		pdfViewer(file) {
			this.$emit('showPdf', file);
		},
		deleteAmendment(amendmentId) {
			let _ = this;
			_.$swal
				.fire({
					title: "Hapus Data Ini?",
					text: "Anda mungkin tidak akan mengembalikannya lagi!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#44A8BD",
					cancelButtonColor: "#D95E6A",
					confirmButtonText: "Ya, Hapus!",
					cancelButtonText: "Batal",
				})
				.then((result) => {
					if (result.value) {
						_.axios
							.delete(`/v3/kontrak/${amendmentId}/amandemen-proses`)
							.then((resp) => {
								if (resp.status) {
									this.$swal.fire({
										title: "Terhapus!",
										text: "Data berhasil dihapus",
										icon: "success",
										confirmButtonColor: "#37BA71",
										confirmButtonText: "OK",
									});
									this.$emit('onRefreshList');
								} else {
									console.log("Failed");
								}
							})
							.catch((err) => {
								_.$toast.warning('Gagal menghapus data!')	
								console.log(err.response);
							});
					}
				})
		}
	},
	mounted() {
		this.getAmendmentStatus()
	}
}
</script>
<style>
td.file-detail {
	display: table-cell !important;
}

button.amandemen-option-btn {
	border: none;
	outline: none;
	background: transparent;
}
</style>