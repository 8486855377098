import { ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import moment from 'moment'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('alphaandnumeric', {
  validate: value => {
  	var Valid = value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])/) !== null;
    if ( !Valid ) {
    	return 'Password harus terdiri dari huruf dan angka'
    }
    return true
  }
});

extend('custom', {
  validate: value => {
  	var Valid = value > 0;
    if ( !Valid ) {
    	return 'Cuaca harus dipilih'
    }
    return true
  }
});

extend("date_order", {
  params: ["prevDate"],
  validate(value, { prevDate }) {
    const today = moment().startOf("day"); // Ensure today is compared without time
    const selectedDate = moment(value).startOf("day"); // Remove time from selected date

    // If no previous date exists, date should not be in the future
    if (!prevDate || prevDate === "undefined") {
      return selectedDate.isSameOrBefore(today) 
        ? true 
        : "Tanggal dokumen tidak boleh di masa depan";
    }

    const previousDate = moment(prevDate).startOf("day"); // Remove time from prevDate

    // If previous date exists, date should be between prevDate and today (inclusive)
    if (selectedDate.isBefore(previousDate)) {
      return "Tanggal dokumen harus setelah atau sama dengan tanggal sebelumnya";
    }

    if (selectedDate.isAfter(today)) {
      return "Tanggal dokumen tidak boleh di masa depan";
    }

    return true;
  }
});
export default ValidationProvider